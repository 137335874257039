body {
  margin: 0;
  font-family: 'SF UI Text';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #464646;
}
/* ul,li {
  padding: 0;
  margin: 0;
  list-style: none;
} */
img {
  max-width: 100%;
}
code {
  font-family: 'SF UI Text';
}

* {
  box-sizing: border-box;
}

.MuiTablePagination-select {
  display: none !important;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('../public/fonts/SFUIText-Bold.eot');
  src: url('../public/fonts/SFUIText-Bold.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/SFUIText-Bold.woff2') format('woff2'),
    url('../public/fonts/SFUIText-Bold.woff') format('woff'),
    url('../public/fonts/SFUIText-Bold.ttf') format('truetype'),
    url('../public/fonts/SFUIText-Bold.svg#SFUIText-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('../public/fonts/SFUIText-Medium.eot');
  src: url('../public/fonts/SFUIText-Medium.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/SFUIText-Medium.woff2') format('woff2'),
    url('../public/fonts/SFUIText-Medium.woff') format('woff'),
    url('../public/fonts/SFUIText-Medium.ttf') format('truetype'),
    url('../public/fonts/SFUIText-Medium.svg#SFUIText-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('../public/fonts/SFUIText-Semibold.eot');
  src: url('../public/fonts/SFUIText-Semibold.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/SFUIText-Semibold.woff2') format('woff2'),
    url('../public/fonts/SFUIText-Semibold.woff') format('woff'),
    url('../public/fonts/SFUIText-Semibold.ttf') format('truetype'),
    url('../public/fonts/SFUIText-Semibold.svg#SFUIText-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('../public/fonts/SFUIText-Regular.eot');
  src: url('../public/fonts/SFUIText-Regular.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/SFUIText-Regular.woff2') format('woff2'),
    url('../public/fonts/SFUIText-Regular.woff') format('woff'),
    url('../public/fonts/SFUIText-Regular.ttf') format('truetype'),
    url('../public/fonts/SFUIText-Regular.svg#SFUIText-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.flash {
  animation-name: flash-animation;
  animation-duration: 1s;
  animation-iteration-count: 2;
}

@keyframes flash-animation {
  0% {
    background: rgba(140, 199, 255);
  }
  25% {
    background: white;
  }
  50% {
    background: rgba(140, 199, 255);
  }
  75% {
    background: white;
  }
  100% {
    background-color: rgba(140, 199, 255);
  }
}
