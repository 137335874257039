.drNucU {

    align-items: center !important;
}

.drNucU>span {
    margin-left: 4px;
    color: #464646 !important;

    padding: 0 !important;
}

.hmyKRq>span {
    margin-left: 4px;
    color: #787878 !important;
    padding: 0 !important;
}

.hmyKRq {

    align-items: center !important;
}