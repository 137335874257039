.PhoneInputInput {
  border: none;
  padding: 16.5px;
  font-size: 16px;
}
.PhoneInputInput:focus-visible {
  outline: none;
}
.invalid{
  
align-items: center !important;
}
.invalid>span{
   margin-left: 4px;
   opacity: 1 !important;
color: #464646 !important;

  padding: 0 !important ;
}
.valid>span{
  margin-left: 4px;
    color: #787878 !important;
    padding: 0 !important;
}
.valid{
 
    align-items: center !important;
}