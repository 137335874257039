.TextArea {
  border: 1px solid #dadada;

  border-radius: 4px;

  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 4px 4px 4px;
  box-sizing: content-box;
  background: none;
  margin: 0;
  width: 100% !important;
}

.TextArea:focus {
  outline: none !important;
  border: 1px solid #436af3;
}

.TextArea:disabled {
  /* outline: none !important;
  border: 1px solid #436af3; */
  background-color: #fafafa;
}
